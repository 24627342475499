






























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Faq extends Vue {
  items = [
    {
      question: "Çevrimiçi eğitim platformu nasıl kullanılır?",
      answer: `<iframe src="https://player.vimeo.com/video/557564964" width="420" height="236" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
    },
    {
      question: "Dijital Vatandaşlık Kimliği nedir?",
      answer: `<p>Dijital Vatandaşlık Kimliği, Diji Vatandaş Eğitim Programı içerisindeki tüm modüllerin başarıyla tamamlanmasıyla, katılımcının güncel dijital teknolojileri ilgilendiren temel konularda global düzeydeki tartışmayı yakalayacak şekilde dijital okuryazar olduğuna işaret eden, dijital dönüşüm süreçlerine kolay adapte olabilecek bir birey olduğunun göstergesi sayılabilecek belgedir.</p><p>Program kapsamında geçerliliği olacak kurum ve kuruluşlar ile anlaşmalar sağlandıkça ayrıca bilgilendirmeler yapılacaktır. Dijital Vatandaşlık Kimliği’nin ulusal ve uluslararası mecrada; adli, idari ve bürokratik düzeyde herhangi bir yasal mahiyeti, sorgulanabilirliği ve geçerliliği yoktur. Yeterlilik belgesidir, yasal bir ayrıcalık sağlamaz.</p>`
    },
    {
      question: "Eğitimler yüz yüze mi?",
      answer:
        "<p>Eğitimlerdeki konu anlatımları interaktif e-ders formatındadır. Video, ses, animasyon ve etkileşim öğeleriyle zenginleştirilerek geliştirilmiş interaktif e-dersler ile dilediğiniz zaman dilediğiniz yerden öğrenebilirsiniz.</p>"
    },
    {
      question: "Eğitimler sonunda sertifika veriliyor mu?",
      answer: `<p>Evet, başarılı olunan her eğitimin sonunda katılım sertifikası verilmektedir.</p>`
    },
    {
      question: "Katılım Sertifikası nedir?",
      answer: `<p>Katılım Sertifikası her bir interaktif eğitim modülünün başarıyla tamamlanması sonrasında, eğitim modülünün bitirildiğine dair belge numarası Diji Vatandaş Eğitim Programınca doğrulanmış belgedir.</p>`
    },
    {
      question: "Badge (rozet) nedir? Nasıl kullanılır?",
      answer: `<p>Badge (rozet), her bir interaktif eğitim modülünün başarıyla bitirilmesi sonrası Katılım Sertifikası ile eş zamanlı sunulan, eğitim içeriğine özgü ve sanal platformlarda biriktirilmesi, sergilenmesi ve paylaşılması mümkün olan dijital bir rozettir.</p>`
    },
    {
      question: "Mobil cihazlardan ders çalışılabilir mi?",
      answer: `<p>Evet. Diji Vatandaşın e-öğrenme altyapısı cihaz ve platform bağımsız olarak tasarlandığından her türlü mobil cihazdan ve işletim sisteminden erişebilirsiniz.</p>`
    }
  ];
}
